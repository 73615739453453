import harvestFrontCurrentStateApi from "@/api/harvestFrontCurrentState.api";
import thingApi from "@/api/thing.api";
import { HARVESTER_STATE_COLORS, HARVESTER_TIME_WITHOUT_CONNECTION, TRUCK_STATE_COLORS, TIMER_COLORS } from "@/components/smart-truck-dispatcher/SmartTruckDispatcherConstant";
import { STATE_TYPE_KEY, TYPE_KEY } from "@colven/common-domain-lib/lib";
import moment from "moment";
import { harvestFrontCurrentStateService } from '@/business/harvestFrontCurrentStateService'
import enterpriseApi from '@/api/enterprise.api'
import { mapCurrentStateService } from '@/business/mapCurrentStateService';
import i18n from '@/i18n'
import ConfigurationsConstants from '@/constants/smartTruckDispatcher.constants'

const getHarvestFrontStatus = async (harvestFrontIds) => {
  const harvestFrontCurrentStates = await harvestFrontCurrentStateApi.getHarvestFrontStatus(harvestFrontIds);
  return harvestFrontCurrentStates;
}
const getHarvestFrontStatusHistory = async (harvestFrontIds, from, to) => {
  const harvestFrontCurrentStates = await harvestFrontCurrentStateApi.getHarvestFrontStatusHistory(harvestFrontIds, from, to);
  return harvestFrontCurrentStates;
}

const parseHarvestFrontsStatus = (harvestFrontsStatus, havestFrontMap, trucksInfo, categories, statesTypes, deviationTime, driveUnits) => {
  const result = [];
  if (harvestFrontsStatus && harvestFrontsStatus.length > 0) {
    for (const harvestFrontStatus of harvestFrontsStatus) {
      result.push(parseHarvestFrontData(harvestFrontStatus, havestFrontMap, trucksInfo, categories, statesTypes, deviationTime, driveUnits));

    }
  }
  return result;
}
const isDisconnectedFront = (harvestFrontStatus) => {
  const serverTimestampDate = moment(harvestFrontStatus.serverTimestamp * 1000);
  const triangulationDate = moment(harvestFrontStatus.timestamp * 1000);

  let diferencia = serverTimestampDate.diff(triangulationDate);
  diferencia = diferencia < 0 ? 0 : diferencia;
  const duracion = moment.duration(diferencia);
  const mins = duracion.minutes();
  const hours = duracion.hours();
  const days = duracion.days();
  if (mins >= 10 || hours > 0 || days > 0) {
    return true;
  }
  return false;
}
const isValidHarvestFrontData = (harvestFrontStatus) => {
  if (!harvestFrontStatus.driveUnit || !harvestFrontStatus.driveUnit.defaultEmptyTripTime || !harvestFrontStatus.driveUnit.defaultFullTripTime) {
    return false;
  }
  return true;
}

const invalidHarvestFrontDataReason = (harvestFrontStatus) => {
  if (!harvestFrontStatus.driveUnit) {
    return ConfigurationsConstants.OutOfConnectionFrontsReasons.NOT_DRIVE_UNIT
  }
  else if (!harvestFrontStatus.driveUnit.defaultEmptyTripTime) {
    return ConfigurationsConstants.OutOfConnectionFrontsReasons.NOT_DEFAULT_EMPTY_TRIP_TIME
  }
  else if (!harvestFrontStatus.driveUnit.defaultFullTripTime) {
    return ConfigurationsConstants.OutOfConnectionFrontsReasons.NOT_DEFAULT_FULL_TRIP_TIME
  }
  else {
    return ConfigurationsConstants.OutOfConnectionFrontsReasons.NOT_CONFIGURED_FRONT
  }
}


const parseHarvestFrontData = (harvestFrontData, harvestFrontMap, trucksInfo, categories, statesTypes, deviationTime, driveUnits) => {
  let frontCategory = categories.filter(category => category.harvestFronts.includes(harvestFrontData.harvestFrontId))[0]
  const noCategoryName = i18n.t(ConfigurationsConstants.NO_CATEGORY_NAME);
  const noCategoryId = ConfigurationsConstants.NO_CATEGORY_NAME;
  const noCategoryObj = {
    _id: noCategoryId,
    name: noCategoryName,
  }
  if (!frontCategory) {
    //asigno a "sin categoría"
    frontCategory = noCategoryObj
  }
  const boxStatus = { count: 0, loaded: 0 };
  const buttonColor = getButtonColor();
  const harvestFrontName = harvestFrontMap.get(harvestFrontData.harvestFrontId);
  const harvesters = getHarvesterData(Object.values(harvestFrontData.things), harvestFrontData, statesTypes);
  const trucks = parseTrucks(harvestFrontData, harvestFrontData.trucks, boxStatus, trucksInfo);

  const trucksRecentDispatched = trucks.filter(truck => truck.isGoing && !truck.tripStartTimestamp)
  const trucksInFront = trucks.filter(truck => truck.tripStartTimestamp && truck.vinculationTimestamp && !truck.disengagementTimestamp)
  const trucksInTravell = trucks.filter(truck => truck.tripStartTimestamp && (!truck.vinculationTimestamp || truck.disengagementTimestamp))

  let actualDriveUnitName = '';
  if (harvestFrontData.driveUnit) {
    const actualDriveunit = driveUnits[harvestFrontData.driveUnit.id]
    actualDriveUnitName = actualDriveunit ? actualDriveunit.properties.Name : ''
  } else {
    actualDriveUnitName = i18n.t(ConfigurationsConstants.NOT_UM_MESSAGE);
  }

  try {
    calculateAndReviewDesviationTimes(trucksRecentDispatched, trucksInFront, trucksInTravell, deviationTime, harvestFrontData)
  }
  catch (error) {
    console.error(error)
  }
  const separatedTrucksInTravell = separateTrucksInTravel(trucksInTravell, 3);

  let frontTimer = null;
  try {
    frontTimer = getHarvestFrontTimer(harvestFrontData, boxStatus);
  }
  catch {
    frontTimer = {
      time: '0',
      color: outOfConectionColors.icon,
      backgroundColor: outOfConectionColors.background
    };
  }

  let outOfConection = false;
  let outOfConectionReason = i18n.t(ConfigurationsConstants.OutOfConnectionFrontsReasons.CONNECTED)
  if (isDisconnectedFront(harvestFrontData)) {
    outOfConection = true;
    outOfConectionReason = i18n.t(ConfigurationsConstants.OutOfConnectionFrontsReasons.OUT_OF_CONNECTION);
  }
  if (!isValidHarvestFrontData(harvestFrontData)) {
    outOfConection = true;
    const reason = invalidHarvestFrontDataReason(harvestFrontData)
    outOfConectionReason = i18n.t(reason);
  }


  if (frontTimer.color === TIMER_COLORS.RED && !harvestFrontData.isStopped && !outOfConection) {
    buttonColor.font = TIMER_COLORS.RED;
  }
  let trucksNeededInFront = null;
  try {
    trucksNeededInFront = getTrucksNeededInFront(harvestFrontData, boxStatus);
  }
  catch {
    trucksNeededInFront = '-';
  }
  try {
    console.log({
      name: harvestFrontName,
      averageTripEmptyTime: getAverageEmptyTrip(harvestFrontData.driveUnit),
      averageTripFullTime: getAverageFullTrip(harvestFrontData.driveUnit),
      averageLoadTime: harvestFrontData.trucksLoadsTimestampMap != null ? getAverageLoadTimes(Object.values(harvestFrontData.trucksLoadsTimestampMap)) : 0
    })
  }
  catch (error) {
    console.error(error)
  }
  //ordenar camiones despachados y en frente
  try{
    trucksRecentDispatched.sort((a, b) => a.dispatchedTimeObject.unix - b.dispatchedTimeObject.unix)
    trucksInFront.sort((a, b) => a.vinculationTimestamp - b.vinculationTimestamp )
  }
  catch{
    console.error('Error al ordenar los camiones')
  }
  


  try{
    console.log({
      name: harvestFrontName,
      averageTripEmptyTime: getAverageEmptyTrip(harvestFrontData.driveUnit),
      averageTripFullTime: getAverageFullTrip(harvestFrontData.driveUnit),
      averageLoadTime: harvestFrontData.trucksLoadsTimestampMap != null? getAverageLoadTimes(Object.values(harvestFrontData.trucksLoadsTimestampMap)) : 0
    })
  }
  catch(error){
    console.error(error) 
  }
  //ordenar camiones despachados y en frente
  try{
    trucksRecentDispatched.sort((a, b) => a.dispatchedTimeObject.unix - b.dispatchedTimeObject.unix)
    trucksInFront.sort((a, b) => a.vinculationTimestamp - b.vinculationTimestamp )
  }
  catch{
    console.error('Error al ordenar los camiones')
  }
  


  const harvestFrontStatus = {
    id: harvestFrontData.harvestFrontId,
    name: harvestFrontName,
    category: frontCategory,
    categoryName: frontCategory ? frontCategory.name : '',
    highlited: false,
    pinned: false,
    truckCount: harvestFrontData.trucks.length,
    harvesters: harvesters,
    btnDispatchFontColor: buttonColor.font,
    btnDispatchBackgroundColor: buttonColor.background,
    frontName: harvestFrontName,
    frontTimer: frontTimer,
    trucks: trucks,
    trucksInFront: trucksInFront,
    trucksRecentDispatched: trucksRecentDispatched,
    stopped: harvestFrontData.isStopped,
    trucksInTravell: separatedTrucksInTravell,
    outOfConection: outOfConection,
    outOfConectionReason: outOfConectionReason,
    serverTimestamp: harvestFrontData.serverTimestamp, // para el historico
    trucksNeededInFront: trucksNeededInFront,
    driveUnitName: actualDriveUnitName
  }
  return harvestFrontStatus;
}
const calculateAndReviewDesviationTimes = (trucksRecentDispatched, trucksInFront, trucksInTravell, deviationTime, harvestFrontData) => {
  const averageTripEmptyTime = getAverageEmptyTrip(harvestFrontData.driveUnit);
  const averageTripFullTime = getAverageFullTrip(harvestFrontData.driveUnit);
  const averageLoadTime = harvestFrontData.trucksLoadsTimestampMap != null ? getAverageLoadTimes(Object.values(harvestFrontData.trucksLoadsTimestampMap)) : 0;

  const averageTripEmptyTimeDuration = moment.duration(averageTripEmptyTime * 1000);
  const averageTripFullTimeDuration = moment.duration(averageTripFullTime * 1000);
  const averageLoadTimeDuration = moment.duration(averageLoadTime * 1000);

  const averageTimes = {
    averageTripEmptyTime: averageTripEmptyTimeDuration,
    averageTripFullTime: averageTripFullTimeDuration,
    averageLoadTime: averageLoadTimeDuration
  }
  reviewDeviationTimes(trucksRecentDispatched, trucksInFront, trucksInTravell, deviationTime, averageTimes);
}
const reviewDeviationTimes = (trucksRecentDispatched, trucksInFront, trucksInTravell, deviationTime, averageTimes) => {
  let deviationTimeInCanchon = Number(deviationTime.timeInCanchon);
  let deviationTimeInHarvestFront = Number(deviationTime.timeInHarvestFront);
  let deviationTimeInTravel = (Number(deviationTime.timeInTravel) / 100) * averageTimes.averageTripEmptyTime._milliseconds;
  let deviationTimeInReturn = (Number(deviationTime.timeInReturn) / 100) * averageTimes.averageTripFullTime._milliseconds;

  const deviationTimeInCanchonUnix = moment.duration(deviationTimeInCanchon * 60 * 1000);
  const deviationTimeInHarvestFrontUnix = moment.duration(deviationTimeInHarvestFront * 60 * 1000);
  const deviationTimeInTravelUnix = moment.duration(deviationTimeInTravel);
  const deviationTimeInReturnUnix = moment.duration(deviationTimeInReturn);
  trucksRecentDispatched.forEach(truck => {
    if (truck.dispatchedTimeObject.unix._milliseconds > deviationTimeInCanchonUnix._milliseconds) {
      truck.desviationAlerted = true;
      truck.desviationTime = truck.dispatchedTimeObject.unix._milliseconds - deviationTimeInCanchonUnix._milliseconds;
      const duration = moment.duration(truck.desviationTime)
      truck.desviationTimeHumanized = Math.floor(duration.asHours()) + ':' + duration.minutes() + ':' + duration.seconds()
    }
    else {
      truck.desviationAlerted = false;
    }
  });

  trucksInFront.forEach(truck => {
    if (truck.inFrontTimeObject.unix._milliseconds - averageTimes.averageLoadTime._milliseconds > deviationTimeInHarvestFrontUnix._milliseconds) {
      truck.desviationAlerted = true;
      truck.desviationTime = truck.inFrontTimeObject.unix._milliseconds - averageTimes.averageLoadTime._milliseconds - deviationTimeInHarvestFrontUnix._milliseconds;
      const duration = moment.duration(truck.desviationTime)
      truck.desviationTimeHumanized = Math.floor(duration.asHours()) + ':' + duration.minutes() + ':' + duration.seconds()
    }
    else {
      truck.desviationAlerted = false;
    }
  });

  trucksInTravell.forEach(truck => {
    if (truck.isGoing) {
      if (truck.tripTimeObject.unix._milliseconds - averageTimes.averageTripEmptyTime._milliseconds > deviationTimeInTravelUnix._milliseconds) {
        truck.desviationAlerted = true;
        truck.desviationTime = truck.tripTimeObject.unix._milliseconds - averageTimes.averageTripEmptyTime._milliseconds - deviationTimeInTravelUnix._milliseconds
        const duration = moment.duration(truck.desviationTime)
        truck.desviationTimeHumanized = Math.floor(duration.asHours()) + ':' + duration.minutes() + ':' + duration.seconds()
      }
      else {
        truck.desviationAlerted = false;
      }
    } else {
      if (truck.tripTimeObject.unix._milliseconds - averageTimes.averageTripFullTime._milliseconds > deviationTimeInReturnUnix._milliseconds) {
        truck.desviationAlerted = true;
        truck.desviationTime = truck.tripTimeObject.unix._milliseconds - averageTimes.averageTripFullTime._milliseconds - deviationTimeInReturnUnix._milliseconds
        const duration = moment.duration(truck.desviationTime)
        truck.desviationTimeHumanized = Math.floor(duration.asHours()) + ':' + duration.minutes() + ':' + duration.seconds()
      }
      else {
        truck.desviationAlerted = false;
      }
    }
  });


}
const getTrucksNeededInFront = (harvestFrontData, boxStatus) => {
  const averageTripEmptyTime = getAverageEmptyTrip(harvestFrontData.driveUnit);
  const averageLoadTime = getAverageLoadTimes(Object.values(harvestFrontData.trucksLoadsTimestampMap));
  const demora = harvestFrontData.truckDelayTimestamp ? (harvestFrontData.serverTimestamp - harvestFrontData.truckDelayTimestamp) : 0;
  const trucksNeededInFrontValue = (averageTripEmptyTime + demora) / (averageLoadTime * 10) - ((boxStatus.count - boxStatus.loaded) / 10)
  let trucksNeededInFront = Math.round(trucksNeededInFrontValue)
  if (trucksNeededInFront === Infinity || isNaN(trucksNeededInFront)) {
    trucksNeededInFront = '-'
  }
  if (trucksNeededInFront > 9) {
    trucksNeededInFront = '+9'
  }
  if (trucksNeededInFront < 0) {
    trucksNeededInFront = 0;
  }

  return trucksNeededInFront;
}
const getHarvestFrontTimer = (harvestFrontData, boxStatus) => {
  const averageTripEmptyTime = getAverageEmptyTrip(harvestFrontData.driveUnit);
  const averageLoadTime = getAverageLoadTimes(Object.values(harvestFrontData.trucksLoadsTimestampMap));
  const demora = harvestFrontData.truckDelayTimestamp ? (harvestFrontData.serverTimestamp - harvestFrontData.truckDelayTimestamp) : 0;
  const seconds = ((boxStatus.count - boxStatus.loaded) * averageLoadTime) - (averageTripEmptyTime + demora);
  //transformo los segundos en minutos
  const duration = moment.duration(seconds, 'seconds');
  let minutos = duration.minutes() + (duration.hours() * 60) + (duration.days() * 24 * 60);
  let color = TIMER_COLORS.RED;
  if (minutos > -10 && minutos < 20) {
    color = TIMER_COLORS.YELLOW;
  }
  else if (minutos >= 20) {
    color = TIMER_COLORS.GREEN;
  }
  const totalTimeString = getDateFormat(Math.abs(seconds));
  const timeString = seconds > 0 ? `+ ${totalTimeString}` : `- ${totalTimeString}`;
  return {
    time: timeString,
    color: color,
    backgroundColor: '#E5E5E5'
  };
}

const getAverageLoadTimes = (thingTimes) => {
  let total = 0;
  let count = 0;
  if (thingTimes && thingTimes.length > 0) {
    for (const times of thingTimes) {
      let prevData = null;
      for (const time of times) {
        if (prevData) {
          total += (time - prevData);
          count++;
        }
        prevData = time;
      }
    }
    return count ? total / count : 0;
  }
  return 0;
}
//separo los camiones para que en el grafico no estén en la misma posicion
const separateTrucksInTravel = (trucks, minSeparation) => {
  if (trucks.length == 0) return trucks
  //los ordeno segun el avance
  trucks.sort((a, b) => a.advancePercentage - b.advancePercentage);
  let lastPercentage = trucks[0].advancePercentage;
  for (let i = 1; i < trucks.length; i++) {
    let newPercentage = lastPercentage + minSeparation;
    if (newPercentage > 100) {
      newPercentage = 100;
    }
    if (trucks[i].advancePercentage < newPercentage) {
      trucks[i].advancePercentage = newPercentage;
    }
    lastPercentage = trucks[i].advancePercentage;

  }

  //ahora aplico la misma logica anterior pero al reves, para separar en caso de que haya 
  //camiones cercanos al 100%
  trucks.sort((a, b) => -a.advancePercentage - b.advancePercentage);
  lastPercentage = trucks[0].advancePercentage;

  for (let i = 1; i < trucks.length; i++) {
    let newPercentage = lastPercentage - minSeparation;
    if (newPercentage < 0) {
      newPercentage = 0;
    }
    if (trucks[i].advancePercentage > newPercentage) {
      trucks[i].advancePercentage = newPercentage;
    }
    lastPercentage = trucks[i].advancePercentage;
  }
  return trucks
}
const parseTrucks = (harvestFrontData, trucks, boxStatus, trucksInfo) => {
  const result = [];
  if (trucks && trucks.length > 0) {
    for (const truck of trucks) {
      const truckInfo = trucksInfo.filter(myTruckInfo => myTruckInfo.idThing === truck.idThing)[0];
      let average = 0;
      let advancePercentage = 0;
      if (truck.tripStartTimestamp && !truck.disengagementTimestamp && truck.track && truck.track.lastState !== STATE_TYPE_KEY.FUERA_SERVICIO) {
        boxStatus.count += truck.boxCount;
        boxStatus.loaded += (truck.loadedBox > truck.boxCount ? truck.boxCount : truck.loadedBox);
      }
      const isGoing = !truck.disengagementTimestamp;
      const timeProgress = getTimeProgress(truck.track, truck.dipatchTimestamp, harvestFrontData.serverTimestamp, truck.outOfServiceTimestamp, truck.tripStartTimestamp);//(truck.track.lastState !== STATE_TYPE_KEY.FUERA_SERVICIO) ? harvestFrontData.serverTimestamp : truck.outOfServiceTimestamp
      if (truck.tripStartTimestamp && !truck.disengagementTimestamp) {
        average = getAverageEmptyTrip(harvestFrontData.driveUnit);
        advancePercentage = (((timeProgress - truck.tripStartTimestamp) * 100) / average);
        advancePercentage = advancePercentage > 100 ? 100 : advancePercentage < 0 ? 0 : advancePercentage;
      } else {
        if (truck.disengagementTimestamp) {
          average = getAverageFullTrip(harvestFrontData.driveUnit);
          advancePercentage = (((timeProgress - truck.disengagementTimestamp) * 100) / average);
          advancePercentage = 100 - advancePercentage;
          advancePercentage = advancePercentage > 100 ? 100 : advancePercentage < 0 ? 0 : advancePercentage;
        }
      }
      // truck.tripStartTimestamp ? (((harvestFrontData.serverTimestamp - truck.tripStartTimestamp) * 100) / average) : 0;
      const dispatchDate = moment(truck.dipatchTimestamp * 1000).format("HH:mm") + "hs";
      const serverTime = moment(harvestFrontData.serverTimestamp * 1000).format("HH:mm:ss") + "hs";
      let tripTime = '';
      let tripTimeObject = {
        unix: 0
      };
      let estimatedArriveTime = '';
      let estimatedArriveTimeObject = {
        unix: 0
      };
      if (truck.tripStartTimestamp) {
        const tripDate = truck.disengagementTimestamp ? moment(truck.disengagementTimestamp * 1000) : moment(truck.tripStartTimestamp * 1000);
        const actualDate = moment(harvestFrontData.serverTimestamp * 1000);
        // Calcula la diferencia en milisegundos
        let diferencia = actualDate.diff(tripDate);
        diferencia = diferencia < 0 ? 0 : diferencia;
        const duracion = moment.duration(diferencia);
        const hrs = Math.floor(duracion.asHours());
        const mins = duracion.minutes();
        const segs = duracion.seconds();
        tripTime = hrs + "h " + mins + "m " + segs + "s";
        tripTimeObject = {
          unix: duracion
        }
        let estimated = tripDate + moment(average * 1000);
        const duracionEstimated = moment.duration(estimated);
        estimatedArriveTime = moment(estimated).format("HH:mm:ss")
        estimatedArriveTimeObject = {
          unix: duracionEstimated
        }
      }
      let inFrontDateTime = '';

      let inFrontTime = '';
      let inFrontTimeObject = {
        unix: 0
      };
      if (truck.vinculationTimestamp) {
        inFrontDateTime = moment(truck.vinculationTimestamp * 1000).format("HH:mm:ss") + "hs";
        const inFrontDate = moment(truck.vinculationTimestamp * 1000);
        const actualDate = moment(harvestFrontData.serverTimestamp * 1000);
        // Calcula la diferencia en milisegundos
        let diferencia = actualDate.diff(inFrontDate);
        diferencia = diferencia < 0 ? 0 : diferencia;

        const duracion = moment.duration(diferencia);
        const hrs = Math.floor(duracion.asHours());
        const mins = duracion.minutes();
        const segs = duracion.seconds();
        inFrontTime = hrs + "h " + mins + "m " + segs + "s";
        inFrontTimeObject = {
          unix: duracion
        };
      }
      let dispatchedTime = '';
      let dispatchedTimeObject = {
        unix: 0
      };
      if (truck.dipatchTimestamp) {
        const inFrontDate = moment(truck.dipatchTimestamp * 1000);
        const actualDate = moment(harvestFrontData.serverTimestamp * 1000);
        // Calcula la diferencia en milisegundos
        let diferencia = actualDate.diff(inFrontDate);
        diferencia = diferencia < 0 ? 0 : diferencia;

        const duracion = moment.duration(diferencia);
        const hrs = Math.floor(duracion.asHours());
        const mins = duracion.minutes();
        const segs = duracion.seconds();
        dispatchedTime = hrs + "h " + mins + "m " + segs + "s";
        dispatchedTimeObject = {
          unix: duracion
        };
      }
      result.push(
        {
          truckNumber: truckInfo ? truckInfo.identificator : truck.idThing,
          label: (truckInfo ? truckInfo.label : truck.idThing) + (truck.caneEquipmentId ? '' : ' S/E'),
          caneEquipmentId: truck.caneEquipmentId,
          advancePercentage: advancePercentage,
          dispatchDate: dispatchDate,
          inFrontDate: inFrontDateTime,
          tripStartTimestamp: truck.tripStartTimestamp,
          color: getTruckColor(truck, isGoing),
          filled: !isGoing,
          isGoing: isGoing,
          withArrow: truck.tripStartTimestamp && truck.track && truck.track.lastState !== STATE_TYPE_KEY.FUERA_SERVICIO,
          identificator: truckInfo ? truckInfo.identificator : '',
          thingName: truckInfo ? truckInfo.thingName : '',
          category: truckInfo ? truckInfo.category : null,
          categoryName: truckInfo ? truckInfo.categoryName : '',
          idThing: truckInfo ? truckInfo.idThing : '',
          tripTime: tripTime,
          inFrontTime: inFrontTime,
          dispatchedTime: dispatchedTime,
          vinculationTimestamp: truck.vinculationTimestamp,
          disengagementTimestamp: truck.disengagementTimestamp,
          serverTime: serverTime,
          inFrontTimeObject: inFrontTimeObject,
          dispatchedTimeObject: dispatchedTimeObject,
          tripTimeObject: tripTimeObject,
          estimatedArriveTime: estimatedArriveTime,
          estimatedArriveTimeObject: estimatedArriveTimeObject,
        }
      )
    }
  }
  return result;
}

const getTimeProgress = (track, dispatchTimestamp, serverTimestamp, outOfServiceTimestamp, tripStartTimestamp) => {
  if (track && tripStartTimestamp && (tripStartTimestamp <= dispatchTimestamp) && (track.trackTimestamp <= dispatchTimestamp)) {
    return tripStartTimestamp;
  }

  return (track.lastState !== STATE_TYPE_KEY.FUERA_SERVICIO) ? serverTimestamp : outOfServiceTimestamp;
}

const getTruckColor = (truck, isGoing) => {
  if (truck.track && truck.track.lastState === STATE_TYPE_KEY.FUERA_SERVICIO) {
    return TRUCK_STATE_COLORS.FUERA_SERVICIO
  }
  //veo si está recien despachado
  if (isGoing && !truck.tripStartTimestamp) {
    return TRUCK_STATE_COLORS.DISPATCHED
  }
  //veo si está en frente
  if (truck.tripStartTimestamp && truck.vinculationTimestamp && !truck.disengagementTimestamp) {
    return TRUCK_STATE_COLORS.IN_FRONT
  }
  else if (isGoing) {
    return TRUCK_STATE_COLORS.IN_TRAVELL_EMPTY
  }
  else if (truck.track && truck.track.lastState === STATE_TYPE_KEY.PROXIMO_INGENIO_1) {
    return TRUCK_STATE_COLORS.PROXIMO_INGENIO_1
  }
  else if (truck.track && truck.track.lastState === STATE_TYPE_KEY.PROXIMO_INGENIO_2) {
    return TRUCK_STATE_COLORS.PROXIMO_INGENIO_2
  }
  else {
    return TRUCK_STATE_COLORS.IN_TRAVELL_FULL
  }
}

const getDateFormat = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const secondDay = seconds % 86400;
  return `${days || ''} ${moment(secondDay * 1000).utc().format("HH:mm:ss")}`
}

const getAverageEmptyTrip = (driveUnit) => {
  if (driveUnit && driveUnit.lastEmptyTrips && driveUnit.lastEmptyTrips.length > 2) {
    let value = 0;
    for (const time of driveUnit.lastEmptyTrips) {
      value += time;
    }
    return (value / driveUnit.lastEmptyTrips.length);
  }
  return driveUnit != null ? driveUnit.defaultEmptyTripTime : 0;
}

const getAverageFullTrip = (driveUnit) => {
  if (driveUnit && driveUnit.lastFullTrips && driveUnit.lastFullTrips.length > 2) {
    let value = 0;
    for (const time of driveUnit.lastFullTrips) {
      value += time;
    }
    return (value / driveUnit.lastFullTrips.length);
  }
  return driveUnit != null ? driveUnit.defaultFullTripTime : 0;
}

const getHarvesterData = (things, harvestFrontData, statesTypes) => {
  const result = [];
  if (things && things.length > 0) {
    for (const thing of things) {
      if (thing.type === TYPE_KEY.HARVESTER) {
        let color = HARVESTER_STATE_COLORS[thing.lastState] || HARVESTER_STATE_COLORS.DEFAULT;
        const timeDiffServer = harvestFrontData.serverTimestamp - thing.trackTimestamp;
        if (timeDiffServer >= HARVESTER_TIME_WITHOUT_CONNECTION) {
          color = HARVESTER_STATE_COLORS.WITHOUT_CONNECTION;
        }
        let timeStopped = '0:00';
        if (thing.TELE_stoppedTime) {
          timeStopped = getDateFormat(Math.abs(thing.TELE_stoppedTime));
        }
        const state = thing.lastState ? statesTypes.get(thing.lastState) : statesTypes.get(thing.state)
        result.push(
          {
            idThing: thing.TELE_thing_id,
            harvesterColor: color,
            velocity: thing.TELE_speed + ' km/h',
            timeStopped: timeStopped,
            stopped: thing.lastState === STATE_TYPE_KEY.PARADA,
            working: thing.lastState === STATE_TYPE_KEY.WORKING,
            state: state,
            stateKey: thing.lastState ? thing.lastState : thing.state,
            stoppedTimeString: thing.stoppedTimeString ? thing.stoppedTimeString : '0'
          }
        );
      }
    }
  }
  return result;
}

const getData = () => {
  let harvestFronts = [];


  for (let index = 0; index < 15; index++) {
    const element = generateRandomJson();
    element.id = (index + 3).toString();
    element.name = frontNames[index];
    harvestFronts.push(element);
  }
  //genero aleatoriamente un frente fuera de conexion y un frente hightlited
  const frontDisable = Math.ceil(Math.random() * harvestFronts.length) - 1
  let frontHighlited = Math.ceil(Math.random() * harvestFronts.length) - 1
  if (frontDisable === frontHighlited) {
    if (frontHighlited === harvestFronts.length - 1) {
      frontHighlited--;
    } else {
      frontHighlited++
    }
  }
  harvestFronts[frontDisable].outOfConection = true;
  harvestFronts[frontDisable].outOfConectionReason = i18n.t(ConfigurationsConstants.OutOfConnectionFrontsReasons.OUT_OF_CONNECTION);
  //harvestFronts[frontHighlited].highlited = true;


  generateTrucksLabels(harvestFronts)
  reviewFrontStatus(harvestFronts)
  return harvestFronts
}

const generateTrucksLabels = (harvestFronts) => {
  harvestFronts.forEach(harvestFront => {
    harvestFront.trucks.forEach(truck => {
      truck.label = truck.truckNumber.toString() + (truck.caneEquipment ? '' : ' S/E');
    })
  });
}
const reviewFrontStatus = (harvestFronts) => {
  harvestFronts.forEach(harvestFront => {
    //Cambiar colores a gris
    if (harvestFront.outOfConection) {
      if (!harvestFront.outOfConectionReason) {
        harvestFront.outOfConectionReason = i18n.t(ConfigurationsConstants.OutOfConnectionFrontsReasons.OUT_OF_CONNECTION);
      }

      harvestFront.trucks.forEach(truck => {
        truck.color = outOfConectionColors.icon;
      })

      harvestFront.harvest1.harvesterColor = outOfConectionColors.icon;
      harvestFront.harvest2.harvesterColor = outOfConectionColors.icon;
      harvestFront.harvest3.harvesterColor = outOfConectionColors.icon;
      harvestFront.btnDispatchFontColor = outOfConectionColors.font;
      harvestFront.btnDispatchBackgroundColor = outOfConectionColors.background;
      harvestFront.frontTimer.backgroundColor = outOfConectionColors.background;
      harvestFront.frontTimer.color = outOfConectionColors.font;
      if (harvestFront.truck1) harvestFront.truck1.color = outOfConectionColors.icon;
      if (harvestFront.truck2) harvestFront.truck2.color = outOfConectionColors.icon;
    }

  });
}

const outOfConectionColors =
{
  icon: '#909090',
  font: '#909090',
  background: '#bcbaba'
}

const frontNames = [
  'CF_C-M',
  'CFRA',
  'CB_C-P',
  'CZ_C-P',
  '2CCON',
  'CI_C-M',
  'CA_C-P',
  'CE_C-P',
  'CC_C-P',
  'CH_C-P',
  'CG_C-P',
  '3CCOR',
  'F Paulina',
  'F El Talar Sur',
  'F San Lorenzo',
  'F Don Herminio',
  'F Florencia',
  'F Caiman',
  'NOA',
  'F El Talar Norte',
  'VINAZA',
]


const generateRandomJson = () => {
  const frontColor = combinationColorGenerator();
  const buttonColor = frontColor;
  return {
    id: Math.ceil(Math.random() * 1000000),
    name: stringGenerator(),
    category: 'Talar',
    highlited: false,
    pinned: false,
    truckCount: Math.ceil(Math.random() * 10),
    harvesters: [
      {
        harvesterColor: harvesterColorGenerator(),
        velocity: '15 km/h',
        timeStopped: '4:25',
        state: 'stoped',
        stoppedTime: '0'
      },
      {
        harvesterColor: harvesterColorGenerator(),
        velocity: null,
        timeStopped: '4:25',
        state: 'otra cosa',
        stoppedTime: '0'
      },
      {
        harvesterColor: harvesterColorGenerator(),
        velocity: '20 km/h',
        timeStopped: '4:25',
        state: 'stoped',
        stoppedTime: '0'

      }
    ],
    harvest1: {
      harvesterColor: harvesterColorGenerator(),
      velocity: '15 km/h',
      timeStopped: '4:25',
      state: 'stoped',
      stoppedTime: '0'
    },
    harvest2: {
      harvesterColor: harvesterColorGenerator(),
      velocity: null,
      timeStopped: '4:25',
      state: 'stoped',
      stoppedTime: '0'
    },
    harvest3: {
      harvesterColor: harvesterColorGenerator(),
      velocity: '20 km/h',
      timeStopped: '4:25',
      state: 'stoped',
      stoppedTime: '0'
    },
    btnDispatchFontColor: buttonColor.font,
    btnDispatchBackgroundColor: buttonColor.background,
    frontName: "CBRE",
    frontTimer: {
      time: "+ 60:60:15 hs",
      color: frontColor.font,
      backgroundColor: frontColor.background
    },
    truck1: {
      truckNumber: 123,
      dispatchDate: "15:35hs",
      color: colorGenerator(),
    },
    truck2: {
      truckNumber: 999,
      dispatchDate: "15:35 hs",
      color: colorGenerator(),
    },
    trucks: arrayTruckGenerator(),
    trucksInFront: arrayTruckGenerator(),
    trucksRecentDispatched: arrayTruckGenerator(),
    borderColor: "red",
  };
}

const getAvailableTrucks = () => {
  var myTrucks = [];
  for (let index = 0; index < availableTrucksNames.length; index++) {
    let myNewTruck = truckGenerator()
    myNewTruck.name = availableTrucksNames[index]
    myNewTruck.completeName = availableTrucksNames[index]
    myNewTruck.thingName = availableTrucksNames[index]

    myTrucks.push(myNewTruck)

  }
  return myTrucks;
}
const availableTrucksNames = [
  'C_291_C-M',
  'C_287_P-C',
  'C_144_P-M',
  'C_650_P-C',
  'C_269_C-P',
  'C_245_C-P',
  'C_237_C-P',
  'C_140_P-M',
  'C_603_P-C',
  'C_671_C-M',
  'C_340_C-P',
  'C_104_P-C',
  'C_246_P-C',
  'C_463_C-M',
  'C_600_C-P',
  'C_605_C-P',
  'C_145_C-M',
  'C_260_C-P',
  'C_433_C-P',
  'C_465_C-P',
  'C_354_C-P',
  'C_309_C-M',
  'C_261_C-P',
  'C_613_C-P'
]
const arrayTruckGenerator = () => {
  const qty = Math.ceil(Math.random() * 5) - 1
  var myTrucks = [];
  for (let index = 0; index < qty; index++) {
    myTrucks.push(truckGenerator())
  }
  return myTrucks;
}
const truckGenerator = () => {
  let myNewTruck =
  {
    _id: Math.ceil(Math.random() * 1000000),
    name: 'Truck',
    //category: 'categoria '+Math.ceil(Math.random()*5).toString(),
    completeName: '',
    thingName: '',
    truckNumber: Math.ceil(Math.random() * 10000),
    dispatchDate: "15:35 hs",
    advancePercentage: Math.ceil(Math.random() * 100),
    color: colorGenerator(),
    filled: booleanGenerator(),
    withArrow: booleanGenerator(),
    status: "Out of service empty",
    isGoing: booleanGenerator(), //define si va o vuelve
    caneEquipment: { id: '123123' },
    tripStartTimestamp: 1720008964,
    category: getRandomCategory(),
  }
  myNewTruck.completeName = myNewTruck.name + ' ' + myNewTruck.truckNumber;
  myNewTruck.thingName = myNewTruck.name + ' ' + myNewTruck.truckNumber;
  myNewTruck.identificator = myNewTruck.truckNumber
  return myNewTruck;
}
const getRandomCategory = () => {
  return randomCategoriesValues[Math.ceil(Math.random() * 2) - 1]
}
const randomCategoriesValues = [
  'Pool',
  'Talar',
  'Herminio'
]
const colorGenerator = () => {
  return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
}
const combinationColorGenerator = () => {
  const indexColor = Math.ceil(Math.random() * 2) - 1
  return availableCombinationsColors[indexColor];
}
const getButtonColor = () => {
  return {
    background: '#E5E5E5',
    font: '#000000'
  }
}
const harvesterColorGenerator = () => {
  const indexColor = Math.ceil(Math.random() * 5) - 1
  return availableHHarvesterColors[indexColor];
}
const availableCombinationsColors = [
  {
    background: '#E5E5E5',
    font: '#000000'
  },
  {
    background: '#E5E5E5',
    font: '#f84e4e'
  },
]
const availableHHarvesterColors = [
  '#8CC051',
  '#4B89DC',
  '#282828',
  '#EB563E',
  '#F6BB43'
]
const booleanGenerator = () => {
  const booleans = [true, false];
  const myBoolean = booleans[Math.floor(Math.random() * booleans.length)];
  return myBoolean;
}
const stringGenerator = () => {
  const token = Math.random().toString(36).slice(6);
  return token;
}
const getThingsData = async () => {
  const selectedEnterprise = JSON.parse(localStorage.getItem('enterpriseId'))
  let trucksAssigned = [];
  try {
    trucksAssigned = (await harvestFrontCurrentStateService.getAsignedTrucks()).trucksAssigned;
  }
  catch {
    trucksAssigned = []
  }

  const categories = (await enterpriseApi.getCategoriesPaginated(0, 0, selectedEnterprise)).data.data;
  const thingsInfo = (await thingApi.getAllThingsByIdsComplete(trucksAssigned));
  const thingProjection = {
    trackTimestamp: 1,
    idThing: 1,
    thingName: 1,
    state: 1,
    trackData: 1
  };

  const noCategoryName = i18n.t(ConfigurationsConstants.NO_CATEGORY_NAME);
  const noCategoryId = ConfigurationsConstants.NO_CATEGORY_NAME;
  const noCategoryObj = {
    _id: noCategoryId,
    name: noCategoryName,
  }
  const thingsState = await mapCurrentStateService.getThingStateByIdThings(trucksAssigned, thingProjection);

  thingsState.map(thing => thing.category = categories.filter(category => category.trucks.includes(thing.idThing))[0])
  //a los que no tienen categoria, le asigno  la categoria por defecto
  thingsState.map(thing => thing.category = thing.category ? thing.category : noCategoryObj)
  thingsState.map(thing => thing.categoryName = thing.category.name)

  thingsState.map(thing => thing.identificator = thingsInfo.filter(thingInfo => thingInfo._id.toString() === thing.idThing)[0].identificator)
  thingsState.map(thing => thing.label = thing.thingName + ' - ' + thing.identificator)
  return thingsState;
}



export const smartTruckDispatcherService = {
  getData,
  arrayTruckGenerator,
  getAvailableTrucks,
  parseHarvestFrontsStatus,
  getHarvestFrontStatus,
  getThingsData,
  getHarvestFrontStatusHistory
}