import service from '@/middleware'

const harvestFrontCurrentStateApi = {
      /**
   * Recurso para obtener el árbol de grupos -> equipos para los selectores
   * @returns
   */
  async getAsignedTrucks() {
    const response = await service.http.get('/api/harvestfrontcurrentstate/asignedTrucks')
    return response
  },
    async saveDeviationTimes (configuration) {
      const response = await service.http.put('/api/harvestfrontcurrentstate/configuration', configuration)
      return response.data
    },  
    /**
     * Get de la configuracion de la empresa
     * @param {*} id
     */
    async getConfiguration (id) {
      const response = await service.http.get(`/api/harvestfrontcurrentstate/configuration/one-by-id/${id}`)
      return response.data
    },
    async assignTrucks (trucksToAssign) {
      const result = await service.http.post('/api/harvestfrontcurrentstate/assignTrucks', trucksToAssign)
      return result
    },
    async getHarvestFrontStatus(harvestFrontIds) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/harvestFrontStatus", { harvestFrontIds });
      return data;
    },
    async getHarvestFrontStatusHistory(harvestFrontIds, from, to) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/harvestFrontStatusHistory", { harvestFrontIds, from, to});
      return data;
    },
    async dispatchTruck(truck,front) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/dispatchTruck", { thingId : truck.idThing ,harvestFrontId: front.id });
      return data;
    },
    async dispatchTruckForced(truck,front) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/dispatchTruckForced", { thingId : truck.idThing ,harvestFrontId: front.id });
      return data;
    },
    async reassingTruck(truck,front) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/reassingTruck", { thingId : truck.idThing, harvestFrontId: front.id });
      return data;
    },
    async unassingTruck(truck) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/unassingTruck", { thingId : truck.idThing });
      return data;
    },
    async stopHarvestFront(front) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/stopHarvestFront", { harvestFrontId: front.id });
      return data;
    },
    async removeStopHarvestFront(front) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/removeStopHarvestFront", { harvestFrontId: front.id });
      return data;
    },
    async timeStoppedHarvester(thingId, harvestFrontId, timeString) {
      const { data } = await service.http.post("api/harvestfrontcurrentstate/timeStoppedHarvester", { thingId, harvestFrontId, timeString });
      return data;
    },
}

export default harvestFrontCurrentStateApi
