import i18n from "@/i18n";

export const HOUR_SELECTOR = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

export const FILTERS = {
    HARVEST_FRONT: {
        id: 'harvestFront',
        name: i18n.t('headers.harvestFront'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    }
}