export const HARVESTER_STATE_COLORS = {
    WORKING: "#4B89DC",
    FALTA_VACIO: "#8CC051",
    PARADA: "#282828",
    WITHOUT_CONNECTION: "#EB563E",
    DEFAULT: "#F6BB43"
}
export const TRUCK_STATE_COLORS = {
    IN_TRAVELL_EMPTY: "#4B89DC",
    IN_TRAVELL_FULL: "#FF99FF",
    PROXIMO_INGENIO_1: "#757575",
    PROXIMO_INGENIO_2: "rgb(255,192,0)",
    FUERA_SERVICIO: "#FF0000",
    DISPATCHED: "rgb(255,192,0)",
    IN_FRONT: "#4B89DC",
}
export const TIMER_COLORS = {
    RED: "red",
    YELLOW: "#F6BB43",
    GREEN: "green",
}
export const HARVESTER_TIME_WITHOUT_CONNECTION = 600;