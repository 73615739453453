import { MapService } from "@/business/mapService";
import i18n from "@/i18n";
import { ReportConversionUtil } from "@colven/common-domain-lib/lib";
import { HARVEST_FRONT_DISCONNECTION_TIME, HARVEST_FRONT_TYPES } from "./constants";

export class HarvestFrontMapService extends MapService {
    constructor() {
        super();
        this.trucksByHarvestFront = new Map();
    }

    mapTrackData(track, thingName) {
        return {
            thingId: track.TELE_thing_id,
            thingName: thingName,
            typeKey: track.type,
            stateKey: track.lastState,
            color: "blue",
            position: {
                "type": "Point",
                "coordinates": [
                    track.TELE_long,
                    track.TELE_lat
                ]
            },
            "trackTimestamp": track.trackTimestamp,
            "enterpriseId": "5e4fe3e1a2c651e4d8b95f0a",
            "communicationTimestamp": track.timestamp,
        }
    }
    updateLastTracks(harvestFrontsStatus, lastUpdateTimestamp, tracks, thingsMap, harvestFrontsMap) {
        this.initTrucksByHarvestFront();
        if (harvestFrontsStatus && harvestFrontsStatus.length > 0) {
            for (const harvestFrontStatus of harvestFrontsStatus) {
                let loadedBoxes = 0;
                if (harvestFrontStatus.things) {
                    for (const thingStatus of Object.values(harvestFrontStatus.things)) {
                        this.validateAndAddThings(thingStatus, lastUpdateTimestamp, thingsMap, harvestFrontStatus, tracks);
                    }
                }
                if (harvestFrontStatus.trucks && harvestFrontStatus.trucks.length > 0) {
                    for (const truckState of Object.values(harvestFrontStatus.trucks)) {
                        const track = truckState.track;
                        this.trucksByHarvestFront.set(track.TELE_thing_id, { id: track.TELE_thing_id, dispatched: true });//ver de optimizar
                        loadedBoxes += truckState.loadedBox;
                        const thingData = thingsMap.get(track.TELE_thing_id);
                        const trackData = this.mapTrackData(track, thingData.name);
                        trackData.stateKey = truckState.status;
                        const caneEquipment = thingsMap.get(truckState.caneEquipmentId);
                        trackData.caneEquipmentName = caneEquipment ? caneEquipment.name : '';
                        trackData.harvestFrontId = harvestFrontStatus.harvestFrontId;
                        tracks.push(trackData);
                    }
                }
                if (harvestFrontStatus.timestamp) {
                    const harvestFrontTrack = this.mapHarvestFrontStatusToTrack(harvestFrontStatus, harvestFrontsMap, loadedBoxes);
                    tracks.push(harvestFrontTrack);
                }
            }
        }
    }

    validateAndAddThings(thingStatus, lastUpdateTimestamp, thingsMap, harvestFrontStatus, tracks) {
        if (thingStatus.timestamp > lastUpdateTimestamp) {
            const thingData = thingsMap.get(thingStatus.TELE_thing_id);
            if (thingData && thingData.name) {
                const trackData = this.mapTrackData(thingStatus, thingData.name);
                trackData.harvestFrontId = harvestFrontStatus.harvestFrontId;
                tracks.push(trackData);
            }
        }
    }

    filterTrucks(markersMap, markersGroup) {
        for (const truckHarvest of this.trucksByHarvestFront.values()) {
            const marker = markersMap.get(truckHarvest.id);
            if (marker) {
                if (!truckHarvest.dispatched) {
                    marker.trackData.removed = true;
                    markersGroup.removeLayer(marker);
                } else {
                    if (marker.trackData.removed) {
                        markersGroup.addLayer(marker);
                    }
                }
            }
        }
    }

    filterMarkersByTrackData(filters, map, markersGroup, markers) {
        Array.from(markers.values()).forEach((layer) => {
            if (layer.trackData) {
                const previousShow = layer.trackData.show;
                const show = this.showByFilters(filters, layer.trackData);
                this.addShowMarker(markersGroup, map, layer, show, previousShow);
            }
        });
    }

    removeHarvestFront(trackData) {
        if (trackData.typeKey === HARVEST_FRONT_TYPES.TRUCK) {
            const truckHarvest = this.trucksByHarvestFront.get(trackData.thingId);
            if (truckHarvest && !truckHarvest.dispatched) {
                trackData.show = false;
                delete trackData.harvestFrontId;
            }
        }
    }

    initTrucksByHarvestFront() {
        for (const truckHarvest of this.trucksByHarvestFront.values()) {
            truckHarvest.dispatched = false;
        }
    }

    getRadiousColor(track) {
        const timeDiff = track.communicationTimestamp - track.trackTimestamp;
        if (timeDiff > HARVEST_FRONT_DISCONNECTION_TIME) {
            return {
                fillColor: "red",
                color: "red"
            }
        }
        return super.getRadiousColor();
    }

    isTypeRadious(type) {
        return type === HARVEST_FRONT_TYPES.HARVEST_FRONT
    }

    mapHarvestFrontStatusToTrack(harvestFrontStatus, harvestFrontsMap, loadedBoxes) {
        const harvestFrontData = harvestFrontsMap.get(harvestFrontStatus.harvestFrontId);
        return {
            typeKey: HARVEST_FRONT_TYPES.HARVEST_FRONT,
            "position": {
                "type": "Point",
                "coordinates": [
                    harvestFrontStatus.position.long,
                    harvestFrontStatus.position.lat
                ]
            },
            color: "blue",
            harvestFrontId: harvestFrontStatus.harvestFrontId,
            thingId: harvestFrontStatus.harvestFrontId,
            "trackTimestamp": harvestFrontStatus.timestamp,
            "thingName": harvestFrontData ? harvestFrontData.name : null,
            "communicationTimestamp": harvestFrontStatus.serverTimestamp,
            "enterpriseId": "5e4fe3e1a2c651e4d8b95f0a",
            loadedBoxes: loadedBoxes,
            boxCount: harvestFrontStatus.trucks.length * 10

        }
    }
    mergeTracksAndHarvestFrontStatus(tracks, harvestFronts, harvestFrontMap) {
        if (harvestFronts && harvestFronts.length > 0) {
            for (const harvestFront of harvestFronts) {
                const harvestFrontData = harvestFrontMap.get(harvestFront.harvestFrontId);
                const newHarvestFront = {
                    typeKey: HARVEST_FRONT_TYPES.HARVEST_FRONT,
                    "position": {
                        "type": "Point",
                        "coordinates": [
                            harvestFront.position.long,
                            harvestFront.position.lat
                        ]
                    },
                    "trackTimestamp": harvestFront.timestamp,
                    "stoppedTime": 0,
                    "stopped": false,
                    "direction": "179",
                    "speed": 0,
                    "thingName": harvestFrontData ? harvestFrontData.name : null,
                    "rpm": 540,
                    "communicationTimestamp": harvestFront.serverTimestamp,
                    "enterpriseId": "5e4fe3e1a2c651e4d8b95f0a",
                    "color": "#0694DE",
                    "engineRunning": true,
                    "tonsPerHour": "119.51",
                    "referenceSpeed": "6.93",
                    "driveUnitName": "211705",
                    "sectorId": "5ede9519a2c651e4d8705f55",
                    "activityId": "5ede940ea2c651e4d8705924",
                }
                tracks.push(newHarvestFront);
            }
        }
    }

    getMCSPopUpByTemplate(track) {
        const state = track.stateKey ? track.stateKey : '';
        const timezone = -3;
        const lang = "es";
        const harvestFrontTemplate = this.getHarvestFrontTemplate(track);
        let communicationTimestampHtml = '';
        if (track.communicationTimestamp != null) {
            const comunicationTimestampConversion = track.communicationTimestamp != null
                ? ReportConversionUtil.applyTimezone(track.communicationTimestamp, timezone)
                : null;
            const comunicationTimestamp = comunicationTimestampConversion ?
                comunicationTimestampConversion.dateString + ' ' + comunicationTimestampConversion.timeString : null;
            const comunicationTimestampColor = this.getTimestampColor(track.communicationTimestamp);
            const comunicationTimestampName = i18n.t('mapReport.COMUNICATION_DATE', { lang });
            communicationTimestampHtml = `${comunicationTimestampName}: <span style='color: ${comunicationTimestampColor} !important;'>${comunicationTimestamp}</span>`;
        }
        let positionTimestampHtml = '';
        if (track.trackTimestamp != null) {
            const positionTimestampConversion = ReportConversionUtil.applyTimezone(track.trackTimestamp, timezone);
            const positionTimestamp = positionTimestampConversion.dateString + ' ' + positionTimestampConversion.timeString;
            const positionTimestampColor = this.getTimestampColor(track.trackTimestamp);
            const positionTimestampName = i18n.t('mapReport.POSITION_DATE', { lang });
            positionTimestampHtml = `${positionTimestampName}: <span style='color: ${positionTimestampColor} !important;'>${positionTimestamp}</span>`;
        }
        const status = this.getStatus(state, track.typeKey);
        const caneEquipmentName = track.caneEquipmentName ? `Equipo cañero: ${track.caneEquipmentName}<br>` : ''

        return `<h4>${track.thingName}</h4>
        <hr>
        ${status}
        ${caneEquipmentName}
        ${positionTimestampHtml}
        <br>
        ${communicationTimestampHtml}
        ${harvestFrontTemplate}
        `
    }

    getStatus(status, type) {
        if (!status) {
            return '';
        }
        if (type === HARVEST_FRONT_TYPES.TRUCK) {
            return `Estado: ${i18n.t('harvestFront.status.' + status)} <br>`;
        }
        return '';
    }

    getHarvestFrontTemplate(harvestFrontData) {
        if (harvestFrontData.typeKey === HARVEST_FRONT_TYPES.HARVEST_FRONT) {
            return `<br>Total Cajas: ${harvestFrontData.boxCount}
            <br>Cajas cargadas: ${harvestFrontData.loadedBoxes}
            `
        }
        return '';
    }
}

export default new HarvestFrontMapService();