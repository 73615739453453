import i18n from '@/i18n';

export const DEFAULT_STOREROOM_RADIUS = 1500;

export const DEFAULT_REFRESH_DATA = 5000;

export const HARVEST_FRONT_DISCONNECTION_TIME = 600;

export const FILTERS = {
    THINGS: {
        id: 'thingSelector',
        name: i18n.t('headers.thing'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined,
        hide: false
    },
    STATES: {
        id: 'stateFilter',
        name: i18n.t('headers.state'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    },
    ACTIVITIES: {
        id: 'activity',
        name: i18n.t('headers.activity'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    },
    SECTOR: {
        id: 'sector',
        name: i18n.t('headers.sector'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    },
    TYPES: {
        id: 'thingType',
        name: i18n.t('headers.thingType'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    },
    CATEGORIES: {
        id: 'categories',
        name: i18n.t('headers.categories'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    },
    HARVEST_FRONT: {
        id: 'harvestFront',
        name: i18n.t('headers.harvestFront'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: false,
        data: [],
        selectedData: [],
        selectAction: undefined
    }
}

export const HARVEST_FRONT_TYPES = {
    TRUCK: 'TRUCK',
    STOREROOM: 'STOREROOM',
    HARVESTER: 'HARVESTER',
    HARVEST_TRACTOR: 'HARVEST_TRACTOR',
    HARVEST_FRONT: 'HARVEST_FRONT'
}