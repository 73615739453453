/* eslint-disable indent */
import harvestFrontCurrentStateApi from '@/api/harvestFrontCurrentState.api'
/**Obtiene los things assignados a canchon */ 

export const harvestFrontCurrentStateService = (function () {
    'use strict'
  
    async function getAsignedTrucks () {
        let asignedTrucks = await harvestFrontCurrentStateApi.getAsignedTrucks();
            return asignedTrucks.data;
      }
      async function assignTrucks (trucksToAssign) {
        return await harvestFrontCurrentStateApi.assignTrucks(trucksToAssign);
      }
      async function dispatchTruck (truck, front) {
        return await harvestFrontCurrentStateApi.dispatchTruck(truck, front);
      }
      async function dispatchTruckForced (truck, front) {
        return await harvestFrontCurrentStateApi.dispatchTruckForced(truck, front);
      }
      async function reassingTruck (truck, front) {
        return await harvestFrontCurrentStateApi.reassingTruck(truck, front);
      }
      async function unassingTruck (truck) {
        return await harvestFrontCurrentStateApi.unassingTruck(truck);
      }
      async function stopHarvestFront (front) {
        return await harvestFrontCurrentStateApi.stopHarvestFront(front);
      }
      async function removeStopHarvestFront (front) {
        return await harvestFrontCurrentStateApi.removeStopHarvestFront(front);
      }
      async function timeStoppedHarvester (thingId, harvestFrontId, timeString) {
        return await harvestFrontCurrentStateApi.timeStoppedHarvester(thingId, harvestFrontId, timeString);
      }
      
    return {
        getAsignedTrucks,
        assignTrucks,
        dispatchTruck,
        dispatchTruckForced,
        reassingTruck,
        unassingTruck,
        stopHarvestFront,
        removeStopHarvestFront,
        timeStoppedHarvester
    }
  })()