import selectorService from '@/business/selectorService';
import { smartTruckDispatcherService } from '@/business/smartTruckDispatcherService';
import thingService from '@/business/thingService';
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import i18n from '@/i18n';
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib';
import { TileLayer } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import filterService from '../../business/filter.service';
import mapHistoryService from '../../business/mapHistoryService';
import mapService from '../../business/mapService';
import { FILTERS, HOUR_SELECTOR } from './constants';
import harvestFrontMapHistoryService from './harvestFrontMapHistory.service';

export default {
    name: 'HarvestFrontMapHistoryComponent',
    components: {
        SelectorComponent,
        AutocompleteComponent
    },
    props: {},
    data() {
        return {
            mapId: 'harvestFrontMapHistoryComponent',
            mapInstance: null,
            loadingSpinner: false,
            markersMap: new Map(),
            harvestFrontsMap: new Map(),
            harvestFrontStatusTimeMap: new Map(),
            thingItems: [],
            thingsMapped: new Map(),
            markersGroup: new Map(),
            harvestFrontIds: [],
            thingSelected: null,
            driveUnitsMakerMap: new Map(),
            thingsMap: new Map(),
            lastUpdateDate: null,
            updateInterval: null,
            harvestFrontFilter: FILTERS.HARVEST_FRONT,
            slider: {
                min: 0,
                max: 10
            },
            timestampValue: 0,
            hourSelected: '00',
            hourSelector: HOUR_SELECTOR,
            playInterval: null,
            driveUnitSwitch: {
                id: "showDriveUnitsSwitch",
                show: false,
                name: i18n.t('trackingMap.filters.showDriveUnits'),
                textTrue: '',
                textFalse: '',
                value: false
            },
            configBase: {
                idElement: 'harvestFrontMapHistoryComponent',
                centerPosition: [-24.475390108274823, -65.03700482239184],
                zoom: 8,
                preferCanvas: false,
                zoomControl: true
            },
            drawer: true,
            selectorModel: selectorService.getSelectorModel(false),
            filters: null
        }
    },
    beforeDestroy() {
    },
    computed: {
        currentTime() {
            return (this.timestampValue ? moment(this.timestampValue * 1000).format("DD/MM/YYYY HH:mm:ss") : "");
        }
    },
    async mounted() {
        this.loadingSpinner = true;
        try {
            await mapService.getUpdatedIcons();
            this.initSelector();
            await this.getAndCreateHarvestFrontSelector();
            this.thingsMapped = await thingService.getAllUserThingMapped();
            this.mapInstance = mapService.createMap(this.configBase);
            this.selectorModel.switches.push(this.driveUnitSwitch);
        } finally {
            this.loadingSpinner = false;
        }
    },
    onDestroy() {
        clearInterval(this.updateInterval);
    },
    methods: {
        initSelector() {
            this.selectorModel.hideAutoReport = true;
            this.selectorModel.showTimeFormatSelector = false;
            this.selectorModel.filterSwitch.value = true;
            this.selectorModel.showGroupEquipmentFilter = false;
            this.selectorModel.hideEquipmentConfigSwitch = true;
            this.selectorModel.hideEquipmentConfigSwitch = true;
            this.selectorModel.disableGetReportButton = false;
            this.selectorModel.selectedCount = 0;
            this.selectorModel.selectionLimit = 60;
            this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM;
            this.selectorModel.singleDaySelect = true;
            this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE;
        },
        beforeDestroy() {
            clearInterval(this.playInterval);
        },
        async changeLinetime(value) {
            const tracks = [];
            const currentStatus = this.harvestFrontStatusTimeMap.get(value) || [];
            harvestFrontMapHistoryService.filterOldData(this.markersMap, this.markersGroup, this.mapInstance, value);
            harvestFrontMapHistoryService.updateLastTracks(Array.from(currentStatus.values()), value, tracks, this.thingsMapped, this.harvestFrontsMap);
            harvestFrontMapHistoryService.filterTrucks(this.markersMap, this.markersGroup);
            await harvestFrontMapHistoryService.updateMarkersByTracks(tracks, this.markersMap, this.markersGroup, this.mapInstance, 1500, this.filters, this.showLabels);
            harvestFrontMapHistoryService.updateRadiousColor(Array.from(this.markersMap.values()), value);
        },
        async getAndCreateHarvestFrontSelector() {
            const harvestFronts = await filterService.getAllHarvestFront();
            this.harvestFrontFilter.data.cleanAndUpdate(harvestFronts);
            this.selectorModel.filters.push(this.harvestFrontFilter);
            this.createHarvestFrontMap(harvestFronts);
        },
        createHarvestFrontMap(harvestFronts) {
            if (harvestFronts && harvestFronts.length > 0) {
                for (const harvestFront of harvestFronts) {
                    this.harvestFrontsMap.set(harvestFront.id, harvestFront);
                }
            }
        },
        async playHistory() {
            if (!this.playInterval) {
                this.playInterval = setInterval(() => {
                    this.changeLinetime(++this.timestampValue);
                    if (this.slider.max === this.timestampValue) {
                        this.pauseHistory();
                    }
                }, 500);
            }
        },
        async pauseHistory() {
            clearInterval(this.playInterval);
            this.playInterval = null;
        },
        centerMap(event) {
            if (event && event.id) {
                mapHistoryService.centerMap(event.id, this.markersMap, 15, this.mapInstance);
            }
        },
        async hourChanged(hour) {
            this.pauseHistory();
            const daySelected = moment(this.filters.sinceDate, 'YYYY-MM-DD').unix();
            this.slider.min = daySelected + (parseInt(hour) * 3600);
            this.slider.max = this.slider.min + 3599;
            this.timestampValue = this.slider.min;
            await this.changeLinetime(this.timestampValue);
        },

        async cleanMap() {
            this.pauseHistory();
            this.mapInstance.eachLayer((layer) => {
                if (!(layer instanceof TileLayer)) {
                    this.mapInstance.removeLayer(layer);
                }
            });
            this.markersMap = new Map();
            this.tracksTime = new Map();
            this.thingItems = [];
            this.thingSelected = null;
            this.driveUnitsMakerMap = new Map();
            this.lastUpdateDate = null;
            this.updateInterval = null;
            this.slider = {
                min: 0,
                max: 10
            };
            this.timestampValue = 0;
            this.hourSelected = '00';
            this.hourSelector = HOUR_SELECTOR;
            this.playInterval = null;
        },
        async getReport(filters) {
            this.loadingSpinner = true;
            try {
                this.drawer = false;
                this.cleanMap();
                this.filters = filters;
                const tracks = [];
                const thingsSelected = filters.groupEquipmentFilterSelected;
                const daySelected = moment(filters.sinceDate, 'YYYY-MM-DD').unix();
                const thingIds = filterService.getThingsDataByGroupsSelected(thingsSelected);
                // const statesMap = await stateService.getStatesNameMapByThingsId(
                //     thingIds.map((thingSelected) => thingSelected.idThing), 'id'
                // );
                const harvestFrontsSelected = filters.filters.find((numericInput) => { return numericInput.id === 'harvestFront' });
                // const storeRoomRadiusValue = 1500;
                this.thingItems = thingIds.map((thingSelected) => { return { name: thingSelected.name, id: thingSelected.idThing } });
                // const tracks = await mapHistoryService.getTrackData(thingIds, daySelected);
                this.harvestFrontIds = harvestFrontsSelected ? (harvestFrontsSelected.selectedData.map(harvestFront => harvestFront.id)) : [];


                this.slider.min = daySelected;
                this.slider.max = daySelected + 3599;//23:59:59
                const harvestFrontResponse = await smartTruckDispatcherService.getHarvestFrontStatusHistory(this.harvestFrontIds, daySelected, daySelected + 86399);
                harvestFrontMapHistoryService.createMapStatusByHistory(harvestFrontResponse, this.harvestFrontStatusTimeMap);
                const firstMomentStatus = this.harvestFrontStatusTimeMap.get(daySelected) || [];

                harvestFrontMapHistoryService.updateLastTracks(Array.from(firstMomentStatus.values()), daySelected, tracks, this.thingsMapped, this.harvestFrontsMap);
                const { markersMap, markersGroup } = await harvestFrontMapHistoryService.createMarkerByTracks(this.mapInstance, tracks, this.defaultRadius, this.showLabels);
                this.markersMap = markersMap;
                this.markersGroup = markersGroup;
                harvestFrontMapHistoryService.filterTrucks(this.markersMap, this.markersGroup);
                harvestFrontMapHistoryService.updateMarkersByTracks(tracks, this.markersMap, this.markersGroup, this.mapInstance, 1500, this.filters, this.showLabels);
                if (this.driveUnitSwitch.value && Object.values(this.driveUnitsMakerMap).length === 0) {
                    const driveUnits = await mapService.getDriveUnitsByEnteprise();
                    this.driveUnitsMakerMap = mapService.addPolygons(this.mapInstance, this.driveUnitsMakerMap, driveUnits);
                }
            } finally {
                this.loadingSpinner = false;
            }
        }
    }
}

