import { HarvestFrontMapService } from "../HarvestFrontMap/harvestFrontMap.service";
import { HARVESTER_TIME_WITHOUT_CONNECTION } from "../smart-truck-dispatcher/SmartTruckDispatcherConstant";

class HarvestFrontHistoryMapService extends HarvestFrontMapService {
    constructor() {
        super();
        this.trucksByHarvestFront = new Map();
    }

    createMapStatusByHistory(harvestFrontsStatus, harvestFrontHistoryMap) {
        if (harvestFrontsStatus && harvestFrontsStatus.length > 0) {
            const lastHarvestFrontStatusMap = new Map();
            for (const harvestFrontStatus of harvestFrontsStatus) {
                const { processTimestamp, harvestFrontId } = harvestFrontStatus;
                let momentLineTime = harvestFrontHistoryMap.get(processTimestamp);
                if (!momentLineTime) {
                    momentLineTime = new Map();
                    harvestFrontHistoryMap.set(processTimestamp, momentLineTime);
                }
                momentLineTime.set(harvestFrontId, harvestFrontStatus);
                this.validateAndCompleteEmtpyTime(lastHarvestFrontStatusMap.get(harvestFrontId), harvestFrontStatus, harvestFrontHistoryMap);
                lastHarvestFrontStatusMap.set(harvestFrontId, harvestFrontStatus);
            }
        }
    }

    updateRadiousColor(thingsMarker, currentMoment) {
        if (thingsMarker && thingsMarker.length > 0) {
            for (const thingMarker of thingsMarker) {
                const data = thingMarker.trackData;
                if (data.show && thingMarker.circle) {
                    let colorData = { color: "blue", fillColor: 'black' };
                    if ((currentMoment - data.trackTimestamp) > HARVESTER_TIME_WITHOUT_CONNECTION) {
                        colorData = {
                            fillColor: "red",
                            color: "red"
                        };
                    }
                    thingMarker.circle.options.color = colorData.color;
                    thingMarker.circle.options.fillColor = colorData.fillColor;
                    thingMarker.circle.setStyle(thingMarker.circle.options);
                }
            }
        }
    }

    validateAndCompleteEmtpyTime(previousStatus, currentStatus, statusMap) {
        if (previousStatus && (currentStatus.processTimestamp - previousStatus.processTimestamp) > 1) {
            this.completeEmptyMap(previousStatus.processTimestamp, currentStatus.processTimestamp, previousStatus, statusMap);
        }
    }

    completeEmptyMap(previousTime, currentTime, previousData, timeLineMap) {
        if (previousData) {
            for (let i = previousTime + 1; i <= currentTime; i++) {
                if (timeLineMap.get(i)) {
                    timeLineMap.get(i).set(previousData.harvestFrontId, previousData);
                } else {
                    const newStatus = new Map();
                    newStatus.set(previousData.harvestFrontId, previousData);
                    timeLineMap.set(i, newStatus);
                }
            }
        }
    }

    filterOldData(markersMap, markersGroup, mapInstance, currentMoment) {
        for (const thingStatus of Array.from(markersMap.values())) {
            const oldData = thingStatus.trackData.communicationTimestamp > currentMoment;
            if (oldData) {
                thingStatus.trackData.show = false;
                if (thingStatus.circle) {
                    mapInstance.removeLayer(thingStatus.circle);
                }
                markersGroup.removeLayer(thingStatus);
            }
        }
    }

    validateAndAddThings(thingStatus, lastUpdateTimestamp, thingsMap, harvestFrontStatus, tracks) {
        if (thingStatus.timestamp <= lastUpdateTimestamp) {
            const thingData = thingsMap.get(thingStatus.TELE_thing_id);
            if (thingData && thingData.name) {
                const trackData = this.mapTrackData(thingStatus, thingData.name);
                trackData.harvestFrontId = harvestFrontStatus.harvestFrontId;
                trackData.serverTimestamp = lastUpdateTimestamp;
                tracks.push(trackData);
            }
        }
    }
}

export default new HarvestFrontHistoryMapService();